import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import { StaticImage } from "gatsby-plugin-image"

const GoatsPage = () => (
  <Layout template="subpage">
    <Seo
        title="Contact M's Mini Farm"
        description={ `Want to reach out? We are happy to chat! Get in contact with M's Mini Farm. `}/>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h1>Contact</h1>
            <p className={ `intro-statement` }>
            We are the Garys, we are goats. We are rambunctious, crazy, insanely cute and cuddly. Above all things, we love treats and attention. We came to M’s Mini Farm Summer 2021, as just wee little kids, born Spring 2021.
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-dark-slate text-center` }>
        <div className={ `container-full inner` }>
            <h2>Meet the Garys</h2>
            <p>Some photos of our escapades, afterall we are goats...</p>
            <div className={ `image-block` }>
            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/ash-hereford-hay.jpeg"
                        alt="Ash and Hereford laying in the hay"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>

            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/big-gary-snuggles.jpeg"
                        alt="Big Gary the Goat Snuggles"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>

            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/browsing-time.jpeg"
                        alt="The goats are out and about browsing"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>

            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/goats-kitty.jpeg"
                        alt="Unsuspecting Kitty and Curious Goats"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>
            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/meal-time.jpeg"
                        alt="Goats sticking their head through the slats on our pallet hay bin to for meal time"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>
            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/just-gary-kitty.jpeg"
                        alt="Just Gary and Farm Kitty"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>
            <div className={ `image` }>
                <div className={ `inner` }>
                    <StaticImage
                        src="../images/goats/just-gary-young.jpeg"
                        alt="A quite young Just Gary"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </div>
            </div>
            </div>
        </div>
    </section>

  </Layout>
)

export default GoatsPage
